<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="留言内容:">
                        <el-input v-model='filters.name' placeholder="请输入留言内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="处理状态:">
                        <el-select v-model="filters.status" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            @current-change="selectCurrentRow"
            highlight-current-row
            style="width: 95%"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="MessageContent" label="留言内容" align="center" min-width="180"></el-table-column>
            <el-table-column prop="Name" label="提交人" align="center" width=""></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" align="center" width=""></el-table-column>
            <el-table-column prop="CreateTime" label="提交时间" min-width="100" align="center"></el-table-column>
            <el-table-column prop='HandleUserName' label='处理人' align='center'></el-table-column>
            <el-table-column prop='HandleTime' label='处理时间' align='center' min-width="100"></el-table-column>
            <el-table-column prop="HandleStatus" label="处理状态" min-width="100" align="center">
                <template scope="scope">
                    <el-tag v-if="scope.row.HandleStatus" type="success">已处理</el-tag>
                    <el-tag v-else type="warning">待处理</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='Remark' label='备注' align='center'></el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="180">
                <template slot-scope="scope">
                    <!-- <el-button @click="handleViews(scope.row)" type="text" size="small">查看</el-button> -->
                    <el-button v-if="!scope.row.HandleStatus" @click="handleDeal(scope.row)" type="text" size="small">处理</el-button>
                    <el-button v-else type="text" size="small">已处理</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新增/编辑 -->
        <el-dialog
            :title="isAddOrEdit?'新增':'编辑'"
            :visible.sync="addFormDialog"
            v-model="addFormDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='问答类型名称:' prop='name'>
                    <el-input v-model='dialogForm.name'></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 添加备注 -->
        <el-dialog
            title="添加备注"
            :visible.sync="addRemarksDialog"
            v-model="addRemarksDialog"
            :close-on-click-modal="false"
            width="70%"
        >
            <el-form :model="addRemarksForm" :rules="addRemarksRules" ref="addRemarksRef" label-width="100px">
                <el-form-item label='备注:' prop='remarks'>
                    <el-input v-model='addRemarksForm.remarks' placeholder="请输入备注内容" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addRemarksDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addRemarksSubmit" :loading="addRemarksLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getOnlineMessageListPage,deleteOnlineMessage,handleOnlineMessage,editOnlineMessageRemark } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
            filters: {
                name: '',
                status: '',
            },
            options: [
                {value:222,name:'全部'},
                {value:1,name:'已处理'},
                {value:0,name:'待处理'},
            ],
            buttonList: [],
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            isAddOrEdit:true,
            addFormDialog:false,
            addLoading:false,
            dialogForm:{
                name:'',
            },
            dialogFormRules:{
                name:[{required:true,message:'请输入问答类型名称',trigger:'blur'}],
            },
            needEditData: '',
            // 添加备注
            addRemarksDialog: false,
            addRemarksLoading: false,
            addRemarksForm: {
                remarks: "",
            },
            addRemarksRules: {
                remarks:[{required:true,message:'请输入备注内容',trigger:'blur'}],
            }
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            this.listLoading = true
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.name){
                params.messageContent = this.filters.name
            }
            if(this.filters.status !== 222 && this.filters.status !== ''){
                params.handleStatus = this.filters.status
            }
            getOnlineMessageListPage(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        // 查询
        getOnlineMessageListPage() {
            this.getListData();
        },
        // 新增
        add() {
            this.dialogForm = {
                name:'',
            }
            this.isAddOrEdit = true;
            this.addFormDialog = true;
        },
        // 编辑
        edit() {
            if(this.needEditData){
                this.dialogForm = {
                    name:this.needEditData.name,
                }
                this.isAddOrEdit = false;
                this.addFormDialog = true;
            }else{
                this.$message({
                    message: '请选择要编辑的数据',
                    type: 'warning'
                });
            }
        },
        // 删除
        handleDel() {
            if(this.needEditData){
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.needEditData.ID
                    }
                    deleteOnlineMessage(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除的数据',
                    type: 'warning'
                });
            }
        },
        // 查看
        handleViews() {

        },
        // 处理
        handleDeal(val) {
            this.$confirm('确定处理？',"提示",{
            }).then(() => {
                var params = {
                    Id: val.ID
                }
                handleOnlineMessage(params).then((res) => {
                    var result = res.data
                    if(result.Success){
                        this.$message({
                            message: result.Message,
                            type: 'success'
                        });
                        this.getListData();
                    }else{
                        this.$message({
                            message: result.Message,
                            type: 'error'
                        });
                    }
                })
            })
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.needEditData = val
        },
        // 提交
        addSubmit() {
            this.$refs['dialogFormRef'].validate(valid => {
                if(valid){
                    this.addLoading = true
                    var _this = this
                    setTimeout(() => {
                        _this.addLoading = false
                    })
                }
            })
        },
        // 添加备注
        addRemarksFun() {
            if(this.needEditData){
                this.addRemarksDialog = true
                this.addRemarksLoading = false
                if(this.needEditData.Remark) {
                    this.addRemarksForm = {
                        remarks: this.needEditData.Remark,
                    }
                } else {
                    this.addRemarksForm = {
                        remarks: "",
                    }
                }
            }else{
                this.$message({
                    message: '请选择要添加备注的数据',
                    type: 'warning'
                });
            }
        },
        // 添加备注提交
        addRemarksSubmit() {
            this.$refs['addRemarksRef'].validate(valid => {
                if(valid){
                    this.addRemarksLoading = true
                    var params = {
                        Id: this.needEditData.ID,
                        Remark: this.addRemarksForm.remarks
                    }
                    console.log("params",params)
                    editOnlineMessageRemark(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message);
                            this.addRemarksDialog = false
                            this.getListData()
                        } else {
                            this.$message.error(result.Message);
                        }
                        this.addRemarksLoading = false
                    })
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getListData();
        },
        //提交时间
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm");
        },
        // 处理时间
        formatHandleTime: function(row, column) {
            return !row.HandleTime || row.HandleTime == ""
            ? ""
            : util.formatDate.format(new Date(row.HandleTime), "yyyy-MM-dd hh:mm");
        }
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>